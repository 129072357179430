<template>
    <section>
        <!-- <button class="btn " v-b-modal="modalId" @click="CreateTime(),isload = true"><i class="fa fa-pencil" ></i></button> -->
        
        <a v-if="type=='button'" class="btn btn-sm" v-b-modal="modalId"  @click="$emit('update_key'),CreateTime(),isload = true">
           <button class="btn btn-primary btn-sm">
                <i class="fa fa-pencil computer" ></i>
               <i class="mdi mdi-clock-start  mobile"> <span class="h5"> Choisir l'heure</span> </i>
            </button>
            <template></template>
        </a>
        <div v-else-if="type=='input'" class="mx-datepicker">
            <div class="mx-input-wrapper">
                <input  @click="CreateTime(),isload = true" :value="value" v-b-modal="modalId" name="date" type="text" autocomplete="off" placeholder="Heure de début" class="mx-input">
                <span class="mx-icon-calendar">
                    <i class="mdi mdi-clock"></i>
                </span>
            </div>
        </div>
         <b-modal class="mt-5 priority" :id="modalId" scrollable title="Sélectionner un horaire" hide-footer>
            <!-- format agenda -->
            <div v-if="isload" class="jumping-dots-loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
                <template v-else>
                <table v-if="available_day != {}">
                    <thead>
                        <tr>
                            <th class="py-2" colspan="2">{{selectedDay}} {{selectedDateTime}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="selectedDay">
                        <tr>
                            <td class="py-2" colspan="2">Matin</td>
                        </tr>

                        <template v-if="day[selectedDay].matin.length > 0">
                            <template v-for="(time,timeid) in day[selectedDay].matin">
                                <tr  class="matin my-3" :style="available(time,available_day[selectedDay].matin)?'display:none':1" :key="'matin'+timeid">
                                    <td>{{time}}</td>
                                    <td v-if="available(time,available_day[selectedDay].matin)" class="unavailable"><button class="btn btn-block btn-sm unavailable" disabled>Réservé</button></td>
                                    <td v-else><button class="reserve btn btn-sm btn-block btn-secondary" @click="selectTime(time)">Réserver</button></td>
                                </tr>
                            </template>
                        </template>
                        <template v-else>
                                <td>Boutique fermé</td>
                        </template>
                        
                        <tr>
                            <td class="py-2" colspan="2">Après-midi</td>
                        </tr>

                        <template v-if="day[selectedDay].soir.length > 0">
                            <template v-for="(time,timeid) in day[selectedDay].soir">
                                <tr class="soir my-3" :style="available(time,available_day[selectedDay].soir)?'display:none':1"  :key="'soir'+timeid">
                                    <td>{{time}}</td>
                                    <td class="text-muted unavailable" v-if="available(time,available_day[selectedDay].soir)" ><button class="btn btn-block btn-sm unavailable" disabled>Réservé</button></td>
                                    <td v-else><button class="reserve btn btn-sm btn-block btn-secondary" @click="selectTime(time)">Réserver</button></td>
                                </tr>
                            </template>
                        </template>
                        <template v-else>
                                <td>Boutique fermé</td>
                        </template>
                        
                    </tbody>
                </table>
            </template>
        </b-modal>
    </section>
</template>

<script>
import axios from 'axios';
import { dateMixin } from '../pages/mixins/date';
export default {
    name: "time-picker-meb",
    mixins:[dateMixin],
    data () {
        return {
            time:"--:--",
            isload : false,
            purchases: [],
            available_day:{
                Lundi: {
                    matin: [],
                    soir: []
                },
                Mardi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00']
                },
                Jeudi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00']
                },
                Vendredi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00']
                },
                Mercredi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00','18:10','18:20','18:30','18:40','18:50','19:00']
                },
                Samedi: {
                    matin: ['10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00']
                },
                Dimanche: {
                    matin: [],
                    soir: []
                }
            },
            day : {
                Lundi: {
                    matin: [],
                    soir: []
                },
                Mardi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00']
                },
                Jeudi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00']
                },
                Vendredi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00']
                },
                Mercredi: {
                    matin: ['09:30','09:40','09:50','10:00','10:10','10:20','10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00','16:10','16:20','16:30','16:40','16:50','17:00','17:10','17:20','17:30','17:40','17:50','18:00','18:10','18:20','18:30','18:40','18:50','19:00']
                },
                Samedi: {
                    matin: ['10:30','10:40','10:50','11:00','11:10','11:20','11:30','11:40','11:50','12:00'],
                    soir:['12:10','12:20','12:30','12:40','12:50','13:00','13:10','13:20','13:30','13:40','13:50','14:00','14:10','14:20','14:30','14:40','14:50','15:00','15:10','15:20','15:30','15:40','15:50','16:00']
                },
                Dimanche: {
                    matin: [],
                    soir: []
                }
            },
            selectedTime: '',
            selectedDay:'',
            test:''
        }
    },
    props: {
        selectedDateTime:String,
        modalId:String,
        myDate:Array,
        myTime:String,
        duration:String,
        open_modal:Boolean,
        type:{
            type:String,
            default:'button'
        },
        value:{
            type:String,
            default:''
        }
    },
    computed:{
    },
    watch: {
        selectedDateTime(){
            this.selectDayString()
        },
        myDate(){
        },
        open_modal(newOpen_modal){
            if(newOpen_modal){
                this.isload = true 
                this.CreateTime()
                this.$bvModal.show(this.modalId)
            }else{
                this.$bvModal.hide(this.modalId)
            }
        },
        myTime(newtime){
            if(newtime){
               this.checktime(newtime.substr(0,4));
            }
        }
    },
    methods: {
        async checktime(time){
            let newtime = time + '0'
            await axios.get('datescheduledservices')
            .then(
                respurchases => {
                    this.purchases =respurchases.data.result.filter(ser => ser.product == null);
                    let arr = []
                    this.purchases.forEach(element => {
                        arr.push({
                            datestart:this.formatDate(element.datestart),
                            dateend:this.formatDate(element.dateend)
                        })
                    });
                
                    this.purchases= arr
                    this.generateTime(this.purchases)
                    this.selectTime(newtime)
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )
        },
        selectDayString(){
            const d = new Date(this.selectedDateTime)
            const Semaine = new Array(7);
            Semaine[0] = "Dimanche";
            Semaine[1] = "Lundi";
            Semaine[2] = "Mardi";
            Semaine[3] = "Mercredi";
            Semaine[4] = "Jeudi";
            Semaine[5] = "Vendredi";
            Semaine[6] = "Samedi";
            this.selectedDay = Semaine[d.getDay()]
        },
        selectTime(time){
            let isavailabel = true
            this.time =time
            
            let sd =new Date(this.selectedDateTime)
                sd.setHours(time.substr(0,2))
                sd.setMinutes(time.substr(3,2)) 
            let ed =new Date(this.selectedDateTime)
                let hours = Number(this.duration.substr(0,2)) + Number(time.substr(0,2)) //hours= dernière heure de la select réservation
                ed.setHours(hours)
                let minutes = Number(time.substr(3,2)) + Number(this.duration.substr(3,2)) 
                ed.setMinutes(minutes)

            // Condition de Blocage de la réservation si elle dépasse l'heure de fermeture de la boutique
            let afternoon_hour= this.day[this.selectedDay]['soir']
            let last_opening_time = afternoon_hour[afternoon_hour.length-1]
            let last_opening_time_hour = last_opening_time.split(':')[0]
            // console.log({last_opening_time_hour})
            // console.log(ed.getHours())
            if(ed.getHours() == last_opening_time_hour && minutes > 0 || ed.getHours() > last_opening_time_hour){ //ex: hours == 19 && minutes > 0 || hours > 19
            alert(`Le ${this.selectedDay}, nous sommes fermés à ${last_opening_time}, La durée de votre prestation dépasse l'heure de fermeture de la boutique, Merci de choisir un autre horaire`)
                this.$emit("close_loader")
                return
                // isavailabel = false
            }

            // Condition de Blocage de la réservation si elle dépasse l'heure d'ouverture de la boutique
            let morning_hour= this.day[this.selectedDay]['matin']
            let start_opening_time = morning_hour[0]
            let start_opening_time_hour = start_opening_time.split(':')[0]
            // start_opening_time_hour
            // console.log("start_opening_time_hour",start_opening_time_hour);

            if(ed.getHours() == start_opening_time_hour && minutes <= 30 || ed.getHours() < start_opening_time_hour){ //ex: hours == 10 && minutes < 30 || hours > 19
                alert(`Le ${this.selectedDay}, nous sommes ouverts à ${start_opening_time}, Merci de choisir un rendez-vous dans notre heure d'ouverture`)
                this.$emit("close_loader")
                return
                // isavailabel = false
            }
            // Condition de blocage de date si la réservation se superpose avec d'autres existantes
            this.purchases.forEach(element => {
                if (ed >= new Date(element.datestart) && ed <= new Date(element.dateend)) {
                isavailabel = false
                }
                
                if (sd <= new Date(element.datestart) && ed >= new Date(element.dateend)) {
                isavailabel = false
                }
            });
            if (isavailabel) {
                this.$emit('saveTime', {
                    // startDate
                    sd: sd,
                    // endDate
                    ed:ed,
                })
            }else{
                alert('La durée de la prestation que vous avez choisi se superpose avec la prochaine réservation, Merci de choisir un autre horaire')
            }
            this.$bvModal.hide(this.modalId)
            this.$emit("close_loader")
        },
        formatDate(dateString){
            let date = dateString.substr(0,19)
            date = date.replace("T", " ")
            return date
        },
        CreateTime(){
            axios.get('datescheduledservices')
            .then(
                respurchases => {
                    this.purchases =respurchases.data.result.filter(ser => ser.product == null);
                    let arr = []
                    this.purchases.forEach(element => {
                        arr.push({
                            datestart:this.formatDate(element.datestart),
                            dateend:this.formatDate(element.dateend)
                        })
                    });
                    this.purchases= arr
                    // this.generateTime(this.purchases)
                    this.generateTime2(this.purchases)
                }
            )
            .catch(
                errpurchases=> {
                    errpurchases
                }
            )
        },
        generateTime(purchase){
                if (this.myDate.length) {
                    this.myDate.forEach(element => {
                        purchase.push(element)
                    });
                }
                // purchase = purchase.filter(ser =>  ser.datestart.substr(0, 10) == this.selectedDateTime)
                purchase = purchase.filter(ser => ser.datestart.substr(0, 10) == this.selectedDateTime 
                ||  this.dateInt(ser.datestart) < this.dateInt(this.selectedDateTime) && this.dateInt(ser.dateend) > this.dateInt(this.selectedDateTime) 
                || this.dateInt(ser.datestart) == this.dateInt(this.selectedDateTime) && this.dateInt(ser.dateend) > this.dateInt(this.selectedDateTime)
                || this.dateInt(ser.dateend) == this.dateInt(this.selectedDateTime) 
                )

                purchase.forEach(element => {
                    if(element.datestart.substr(0,10) == element.dateend.substr(0,10) ) {
                        if (this.heureInt(element.datestart) < 12 && this.heureInt(element.dateend) <= 12 ) {
                           this.available_day[this.selectedDay]["matin"] = 
                           this.available_day[this.selectedDay]["matin"].filter(function(value){ 
                                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))) && Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                       }
                       if (this.heureInt(element.datestart) > 12) {
                            this.available_day[this.selectedDay]["soir"] =
                            this.available_day[this.selectedDay]["soir"].filter(function(value){ 
                                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))) && Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                       }
                       if (this.heureInt(element.datestart) <= 12 && this.heureInt(element.dateend) > 12 ) {
                            this.available_day[this.selectedDay]["matin"] =  this.available_day[this.selectedDay]["matin"].filter(function(value){ 
    
                                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))))
                                            });
                            this.available_day[this.selectedDay]["soir"] =  this.available_day[this.selectedDay]["soir"].filter(function(value){ 
                                                return  !( Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                       }
                       console.log("elemnt date start", element.datestart);
                    }
                    // si la date début et date de fin sont différentes
                    else{
                        // si l'heure de début commence le matin
                        if(this.heureInt(element.datestart) <= 12 && element.datestart.substr(0,10) == this.selectedDateTime) {
                            this.available_day[this.selectedDay]["matin"] =
                            this.available_day[this.selectedDay]["matin"].filter(function(value){ 
                                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))) && Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                            this.available_day[this.selectedDay]["soir"] = []
                        }
                        // si l'heure de début commence l'après midi
                        if(this.heureInt(element.datestart) > 12 && element.datestart.substr(0,10) == this.selectedDateTime) {
                             this.available_day[this.selectedDay]["soir"] =
                            this.available_day[this.selectedDay]["soir"].filter(function(value){ 
                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))))
                            });
                        }
                        // si l'heure de fin se termine le matin
                        if(this.heureInt(element.dateend) <= 12 && element.dateend.substr(0,10) == this.selectedDateTime) {
                             this.available_day[this.selectedDay]["matin"] =
                            this.available_day[this.selectedDay]["matin"].filter(function(value){ 
                                return  !(Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                            });
                        }
                        // si l'heure de fin se termine l'après midi
                        if (this.heureInt(element.dateend) > 12 && element.dateend.substr(0,10) == this.selectedDateTime) {
                            this.available_day[this.selectedDay]["matin"] = []
                            this.available_day[this.selectedDay]["soir"] =
                            this.available_day[this.selectedDay]["soir"].filter(function(value){
                                return  !(Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                            });
                        }
                        // si la date de début et date de fin ne sont pas identiques à la date sélectionnée (entre les dates)
                        if(element.datestart.substr(0,10) != this.selectedDateTime && element.dateend.substr(0,10) != this.selectedDateTime){
                            this.available_day[this.selectedDay]["matin"] = []
                            this.available_day[this.selectedDay]["soir"] = []
                        }
                    }
                });
                
                this.isload = false
        },
        // génération du temps avec la durée des prestations
        generateTime2(purchase){
                if (this.myDate.length) {
                    this.myDate.forEach(element => {
                        purchase.push(element)
                    });
                }
                // purchase = purchase.filter(ser =>  ser.datestart.substr(0, 10) == this.selectedDateTime)
                purchase = purchase.filter(ser => ser.datestart.substr(0, 10) == this.selectedDateTime 
                ||  this.dateInt(ser.datestart) < this.dateInt(this.selectedDateTime) && this.dateInt(ser.dateend) > this.dateInt(this.selectedDateTime) 
                || this.dateInt(ser.datestart) == this.dateInt(this.selectedDateTime) && this.dateInt(ser.dateend) > this.dateInt(this.selectedDateTime)
                || this.dateInt(ser.dateend) == this.dateInt(this.selectedDateTime) 
                )

                purchase.forEach(element => {
                    // si même jour
                    if(element.datestart.substr(0,10) == element.dateend.substr(0,10) ) {
                        // convertir l"heure de début avec la durée de prestations .. (date de début - durée de prestation)
                        let datestart = this.reduceMinutesSpace(element.datestart,this.$store.getters.totalDuration)
                        if (this.heureInt(datestart) < 12 && this.heureInt(element.dateend) <= 12 ) {
                           this.available_day[this.selectedDay]["matin"] = 
                           this.available_day[this.selectedDay]["matin"].filter(function(value){ 
                                                return  !(Number((value.replace(':',''))) >= Number((datestart.substr(11, 5).replace(':',''))) && Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                       }
                       if (this.heureInt(datestart) > 12) {

                            this.available_day[this.selectedDay]["soir"] =
                            this.available_day[this.selectedDay]["soir"].filter(function(value){ 
                                                return  !(Number((value.replace(':',''))) >= Number((datestart.substr(11, 5).replace(':',''))) && Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                       }
                       if (this.heureInt(datestart) <= 12 && this.heureInt(element.dateend) > 12 ) {
                            this.available_day[this.selectedDay]["matin"] =  this.available_day[this.selectedDay]["matin"].filter(function(value){ 
    
                                                return  !(Number((value.replace(':',''))) >= Number((datestart.substr(11, 5).replace(':',''))))
                                                // return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))))
                                            });
                            this.available_day[this.selectedDay]["soir"] =  this.available_day[this.selectedDay]["soir"].filter(function(value){ 
                                                return  !( Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                       }
                    //    date de fermeture + durée de prestation
                    let time_closed = this.day[this.selectedDay]["soir"]
                    time_closed = time_closed[time_closed.length-1]
                    let date_closed = this.reduceMinutesSpace(this.selectedDateTime +" "+ time_closed, this.$store.getters.totalDuration)
                    this.available_day[this.selectedDay]["soir"] = this.available_day[this.selectedDay]["soir"].filter(function(value){
                        return !(Number((value.replace(':',''))) >= Number((date_closed.substr(11, 5).replace(':',''))))
                    })

                    }
                    // si la date début et date de fin sont différentes
                    else{
                        // si l'heure de début commence le matin
                        if(this.heureInt(element.datestart) <= 12 && element.datestart.substr(0,10) == this.selectedDateTime) {
                            this.available_day[this.selectedDay]["matin"] =
                            this.available_day[this.selectedDay]["matin"].filter(function(value){ 
                                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))) && Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                                            });
                            this.available_day[this.selectedDay]["soir"] = []
                        }
                        // si l'heure de début commence l'après midi
                        if(this.heureInt(element.datestart) > 12 && element.datestart.substr(0,10) == this.selectedDateTime) {
                             this.available_day[this.selectedDay]["soir"] =
                            this.available_day[this.selectedDay]["soir"].filter(function(value){ 
                                return  !(Number((value.replace(':',''))) >= Number((element.datestart.substr(11, 5).replace(':',''))))
                            });
                        }
                        // si l'heure de fin se termine le matin
                        if(this.heureInt(element.dateend) <= 12 && element.dateend.substr(0,10) == this.selectedDateTime) {
                             this.available_day[this.selectedDay]["matin"] =
                            this.available_day[this.selectedDay]["matin"].filter(function(value){ 
                                return  !(Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                            });
                        }
                        // si l'heure de fin se termine l'après midi
                        if (this.heureInt(element.dateend) > 12 && element.dateend.substr(0,10) == this.selectedDateTime) {
                            this.available_day[this.selectedDay]["matin"] = []
                            this.available_day[this.selectedDay]["soir"] =
                            this.available_day[this.selectedDay]["soir"].filter(function(value){
                                return  !(Number((value.replace(':',''))) <= Number((element.dateend.substr(11, 5).replace(':',''))))
                            });
                        }
                        // si la date de début et date de fin ne sont pas identiques à la date sélectionnée (entre les dates)
                        if(element.datestart.substr(0,10) != this.selectedDateTime && element.dateend.substr(0,10) != this.selectedDateTime){
                            this.available_day[this.selectedDay]["matin"] = []
                            this.available_day[this.selectedDay]["soir"] = []
                        }
                    }
                });
                
                this.isload = false
        },
        heureInt(mydate){
            var stringdate;
            stringdate =  mydate.substr(11, 5)
            return Number(stringdate.replace(':','.'))
        },
        dateInt(mydate){
            var stringdate;
            stringdate =  mydate.substr(0, 10)
            stringdate = stringdate.replace(/-/g,'')
            stringdate = stringdate.replace(' ','')
            stringdate = stringdate.replace(':','')
            return Number(stringdate)
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
        available(time,availableDay){
            return !availableDay.includes(time)
        },
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
    },
    mounted(){
        this.selectDayString()
        if(this.myTime){
            this.checktime(this.myTime)
        }
    }
}
</script>
<style scoped>
.priority{
    z-index: 4000;
}

/* agenda */
table {
    width: 100%;
    text-align: center;
}
table,
td {
     
}
tbody tr {
    /* background: rgb(255, 172, 213); */
    /* border-top: 1px rgb(224, 224, 224) solid; */
    border-top: 1px rgb(108, 108, 108) solid;
    
}
thead,
tfoot {
    background-color: #333;
    color: #fff;
}
/* couleur par liste */
tbody tr.matin:nth-of-type(6n-1) {
    /* border-top: 1px solid black !important; */
}
tbody tr.soir:nth-of-type(6n) {
    /* border-top: 1px solid black !important; */
}

/* affichage du bouton lors de l'hover */
@media screen and (min-width: 992px) {
    .reserve {
        visibility: hidden;
    }
}
@media screen and (max-width: 992px) {
    tr .reserve {
        background: #c49f2e;
    }
}
tr:hover .reserve {
    visibility: visible;
    background: #c49f2e;
}

.unavailable {
    font-family: "ubuntu", sans-serif;
    color: #838383;
}
  td.unavailable {
      background: #d5d5d5;
  }
</style>
<template>
    <section>
        <div class="row">
            <div class="col-md-12 time">
                <VueCal
                :time-from="9.5 * 60"
                @cell-click="getTime"
                :time-to="19 * 60"
                :time-step="10"
                :selected-date="dateString()"
                :events="scheduled"
                locale="fr"
                :hideWeekdays="[1,7]"
                :disableViews="['years','year']"
                :dblclickToNavigate="false"
                :clickToNavigate ="false"
                show-time-in-cells
                overlapsPerTimeStep
                :min-date="minDate"
                :special-hours="specialHours"
                
                >
                <template #time-cell="{ hours, minutes }">
                    <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
                    <strong v-if="!minutes" style="font-size: 15px"> <i class="fa fa-clock-o" ></i>{{ hours }}</strong>
                    <!-- <span v-else style="font-size: 11px">{{ minutes }}</span> -->
                    </div>
                </template>
                </VueCal>
            </div>
        </div>
    </section>
</template>
<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
// import 'vue-cal/dist/i18n/fr.js'
import {dateMixin} from './../pages/mixins/date.js'
// import { mapState } from 'vuex'
export default {
    name:'full-time-picker-meb',
    mixins:[dateMixin],
    components:{
        VueCal,
        // mapState
    },
    data(){
        return {
            events:[
                {
                    start: '2022-01-01 10:30',
                    end: '2022-09-01 11:30',
                    title: 'indisponible',
                    class: 'past'
                }
            ],
            disabled:"years"
        }
    },
    props:['myreservation'],
    computed:{
        specialHours(){
            // alert(this.$store.getters.totalDuration)
            return  {
                2: [
                    { 
                        from: (18 - this.$store.getters.totalDuration) * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'    
                    }
                ],
                3:[
                    {
                        from: (19 - this.$store.getters.totalDuration) * 60 ,to: 19 * 60 ,class:'closed',
                    }
                ],
                4: [
                    { 
                        from: (18 - this.$store.getters.totalDuration) * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'
                    }
                ],
                5: [
                    { 
                        from: (18 - this.$store.getters.totalDuration) * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'
                    }
                ],
                6: [
                    { 
                        from: (9.5 - this.$store.getters.totalDuration) * 60, to: 10.5 * 60, class: 'closed',
                        // label: 'Fermer'
                    },
                    { 
                        from: (16 - this.$store.getters.totalDuration) * 60, to: 19 * 60, class: 'closed',
                        // label: 'Fermer'
                     }
                ],
                }
        },
        scheduled(){
            this.reduceMinutesFromEndDate()


            let myreservation = this.myreservation
            let tableScheduled=[...myreservation]
            for(let event of this.$store.state.cart.scheduled) {
                tableScheduled.push({
                    start:this.reduceMinutes(event.datestart,this.$store.getters.totalDuration),
                    end:this.addTenMinutesToTime(this.formatDate(event.dateend)),
                    // title:'indisponible',
                    class:'unavailable'
                })
            }
            return tableScheduled
        },
         minDate () {
            return new Date()
        },
    },
    methods:{
        reduceMinutesFromEndDate(){
            console.log("specialHours",this.specialHours["2"])
        },
        // formatDate(dateString){
        //     let date = new Date(dateString)
        //     date = date.toISOString().split('T')[0]+' '+ date.toISOString().split('T')[1].slice(0,5)
        //     return date
        // },
        
        getTime(data){
            let time = data.toLocaleTimeString('fr-Fr');
            let date = data.toISOString().split('T')[0]
            let datetime = []
            datetime[0] = date
            datetime[1] = time.substring(0,4)
            this.$emit('selected_time',datetime)
        },
        dateString(mydate){
            var stringdate;

            if (!mydate) {
                mydate = new Date();    
            }
            stringdate =  mydate.getFullYear() + "-"
                + this.digit((mydate.getMonth()+1))  + "-" 
                + this.digit(mydate.getDate()) + " "

                + this.digit(mydate.getHours()) + ":"  
                + this.digit(mydate.getMinutes()) + ":" 
                + this.digit(mydate.getSeconds())

            return stringdate
        },
        digit(time){
            if (Number(time)< 10) {
                time = "0"+time
            }
            return time
        },
    },
    mounted(){
        this.$store.dispatch('get_scheduled')
        // this.formatDate()
    },
}
</script>
<style scoped>
.time{
    min-height: 50rem;
}
::v-deep .unavailable{
    background: #fffaf0 repeating-linear-gradient( -45deg, rgba(223, 223, 223, 0.25), rgba(210, 210, 210, 0.25) 5px, rgba(255, 255, 255, 0) 5px, rgba(255, 255, 255, 0) 15px );
    color: #f6984c;
}
:deep .vuecal__event-time{
    display: none;
}
/* animation survol sur les heures */
:deep .cell-time-label:hover{
    transform: scaleX(1.03);
    background: #EFB90B;
    color: #fff;
    cursor: pointer;
    opacity: 1;
    z-index:3;
    font-size: 1em
}
:deep .cell-time-label{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* désactivation de l'animation survol sur les heures sur les dates passées */
:deep .vuecal__cell--disabled .cell-time-label:hover{
    transform: scaleX(1);
    background: none;
    color:#00000040;
    cursor: not-allowed;
    opacity: .3;
}

:deep .past{
    background: red
}
:deep .vuecal__no-event{
    display: none;
}
:deep .vuecal__event.unavailable {
    width: 100% !important;
    left:0 !important
}
:deep .myreservation {
    background: #EFB90B;
    color: #fff;
}
/* ::v-deep .vuecal__flex.vuecal__cell-content:hover {
}
::v-deep .vuecal__flex.vuecal__cell-content:focus {
    transform: scaleX(1.03);
    background: #EFB90B;
    cursor: pointer;
} */
:deep .closed {
  background: #fffaf0 repeating-linear-gradient( -45deg, rgba(223, 223, 223, 0.25), rgba(210, 210, 210, 0.25) 5px, rgba(255, 255, 255, 0) 5px, rgba(255, 255, 255, 0) 15px );
    color: #f6984c;
}
 .vuecal__time-cell-line.hours:before {border-color: #5b6319;}
</style>